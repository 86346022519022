@import "../../../../index.scss";

.login {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin: auto;
  margin-top: 50px;

  &__logo {
    width: 150px;
    margin: $gap2x;
  }

  &__title {
    font-size: $h1-font-size;
    margin-bottom: 5px;
  }

  &__leyend {
    font-size: $h3-font-size;
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: $h2-font-size;
    margin-bottom: 20px;
  }

  &__input {
    text-align: left;
  }

  &__register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    &-text {
      font-size: $h3-font-size;
      margin-bottom: 10px;
    }
  }
  &__payments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    &-text {
      font-size: $h3-font-size;
      margin-bottom: 10px;
    }
    &-primary {
      // TODO: Add styles for
    }
  }

  #primary-button {
    @include primary-button;
  }

  #secondary-button {
    @include secondary-button;
  }
}

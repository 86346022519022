@import "../../../index.scss";

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $h1-font-size;
  font-weight: $font-weight-bold;
  color: $secondaryColor;
  margin-top: $gap2x;
  margin-bottom: $gap;
}

.subtitle {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: $h2-font-size;
	font-weight: $font-weight-bold;
	color: $secondaryColor;
	margin-top: $gap;
	margin-bottom: $gap;
}

.table {
  margin: $gap;
  width: 100% !important;
}

@import "../../../../index.scss";

.detalle-estudio {
	padding: $gap;
	#primary-button {
		@include primary-button;
	}

	#secondary-button {
		@include secondary-button;
	}
}

.input-file,
.dropdown {
	margin: $gap 0;
	display: block;
	width: 100%;
}

.input-button {
	display: none;
}

.botonera {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: $gap;
}

#upload-button {
	@include primary-button;
	padding: 11px 30px;
	margin: 0;
}

.input-logs {
	width: 100%;
}

.buttons-logs {
	display: flex;
	justify-content: center;
	align-items: center;
	// margin: $gap;
}
